export const environment = {
  production: true,
  name: 'prod',
  version: 'b9f64a7afa223fb17f231552351f843c38d02b60',
  oidcProvider: 'oidc.bexio-prod',
  sentryDsn: 'https://7c7faa0fc9754ec3992148e548d9a683@o1347949.ingest.sentry.io/6626946',
  api_url: 'https://europe-west6-leaf-cockpit.cloudfunctions.net',
  firebase: {
    projectId: 'leaf-cockpit',
    appId: '1:746479920946:web:bcff2f28baf2b3dd47992b',
    storageBucket: 'leaf-cockpit.appspot.com',
    locationId: 'europe-west6',
    apiKey: 'AIzaSyBtCdJUHOINTaan-NRtPm2w68195Bync-I',
    authDomain: 'leaf-cockpit.firebaseapp.com',
    messagingSenderId: '746479920946',
    measurementId: 'G-YNQQDNG1RM',
  },
  posthog: {
    enabled: true,
    apiKey: 'phc_Jsut6ohRyeEo615UImU1TvAZG0FkevCbMCJHC5z7WI8',
    apiHost: 'https://app.posthog.com',
  },
  features: {
    csvExport: false,
    valuation: false,
  }
};
