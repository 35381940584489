import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ScenarioService } from '../service/scenario.service';
import { Store } from '@ngrx/store';
import {
  createScenario,
  createScenarioFailure,
  createScenarioSuccess,
  createScenarioWithPreviousYearSuccess,
  deleteScenario,
  deleteScenarioFailure,
  deleteScenarioSuccess,
  downloadPreviousYearCsv,
  duplicateScenario,
  duplicateScenarioFailure,
  duplicateScenarioSuccess,
  getScenarios,
  getScenariosFailure,
  getScenariosSuccess,
  getScenarioValues,
  getScenarioValuesFailure,
  getScenarioValuesSuccess,
  setScenarioValues,
  setScenarioValuesFailure,
  setScenarioValuesSuccess,
  setScenarioName,
  setScenarioNameFailure,
  setScenarioNameSuccess,
  setScenarioValue,
  setScenarioValueFailure,
  setScenarioValueSuccess
} from './scenario.action';
import { selectIsUserConnected } from '../../authentication/store/authentication.selector';
import { filter, map, mergeMap, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from '../../home/services/local-storage.service';
import {
  getConnectedUserSuccess,
  userSignInWithBexioSuccess
} from '../../authentication/store/authentication.action';
import { selectCurrentScenarioId, selectScenarios } from './scenario.selector';
import { hideLoading, showLoading } from '../../core/store/app.actions';
import { selectMonthlyAccountsWithinPrevSpan } from './monthly-accounts.selector';
import { saveAs } from 'file-saver';
import { selectAccounts } from './accounting.selector';

@Injectable({
  providedIn: 'root',
})
export class ScenarioEffects {

  constructor(
    private actions$: Actions,
    private budgetService: ScenarioService,
    private localStorageService: LocalStorageService,
    private store: Store<any>
  ) {
  }

  scenarioLoadingEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        createScenario,
        deleteScenario,
        duplicateScenario,
        getScenarios,
        setScenarioValue,
        getScenarioValues,
        setScenarioValues,
        setScenarioName,
      ),
      map(() => showLoading())
    );
  });

  scenarioLoadedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        createScenarioSuccess,
        createScenarioFailure,
        createScenarioWithPreviousYearSuccess,
        deleteScenarioSuccess,
        deleteScenarioFailure,
        duplicateScenarioSuccess,
        duplicateScenarioFailure,
        getScenariosSuccess,
        getScenariosFailure,
        getScenarioValuesSuccess,
        getScenarioValuesFailure,
        setScenarioValueSuccess,
        setScenarioValueFailure,
        setScenarioValuesSuccess,
        setScenarioValuesFailure,
        setScenarioNameSuccess,
        setScenarioNameFailure,
      ),
      map(() => hideLoading())
    );
  });

  getScenariosIfLoggedIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getConnectedUserSuccess, userSignInWithBexioSuccess),
      map(() => getScenarios())
    );
  });

  getScenariosEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getScenarios),
      mergeMap(() => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.getScenarios(companyId).pipe(
            map(scenarios => getScenariosSuccess({ scenarios })),
            catchError((error) => of(getScenariosFailure({ error })))
          );
        }
        return of(getScenariosFailure({ error: new Error('Missing company id') }));
      }),
    );
  });

  getScenariosAfterCreatingTheFirstOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setScenarioValueSuccess),
      concatLatestFrom(() => [this.store.select(selectScenarios)]),
      filter(([, scenarios]) => scenarios.length === 0),
      map(() => getScenarios())
    )
  });

  saveScenarioValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setScenarioValue),
      concatLatestFrom(() => [
        this.store.select(selectIsUserConnected)
      ]),
      filter(([, connected]) => connected),
      map(([{ monthlyAccount }]) => monthlyAccount),
      concatLatestFrom(() => [
        this.store.select(selectCurrentScenarioId),
      ]),
      mergeMap(([monthlyAccount, scenarioId ]) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.saveScenarioValue(companyId, monthlyAccount, scenarioId).pipe(
            map((scenarioId) => setScenarioValueSuccess({ scenarioId, monthlyAccount })),
            catchError((error) => of(setScenarioValueFailure({ error })))
          );
        }
        return of(setScenarioValueFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  saveScenarioValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setScenarioValues),
      concatLatestFrom(() => [
        this.store.select(selectIsUserConnected),
      ]),
      filter(([, connected]) => connected),
      map(([{ monthlyAccountList }]) => monthlyAccountList),
      concatLatestFrom(() => [
        this.store.select(selectCurrentScenarioId),
      ]),
      mergeMap(([monthlyAccountList, scenarioId ]) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.saveScenarioValue(companyId, monthlyAccountList, scenarioId).pipe(
            map((scenarioId) => setScenarioValuesSuccess({ scenarioId, monthlyAccountList })),
            catchError((error) => of(setScenarioValuesFailure({ error })))
          );
        }
        return of(setScenarioValuesFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  getScenarioValuesAfterRetrievingScenariosEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getScenariosSuccess),
      concatLatestFrom(() => [this.store.select(selectCurrentScenarioId)]),
      filter(([, scenarioId]) => !!scenarioId),
      map(([, scenarioId]) => getScenarioValues({ scenarioId: scenarioId! }))
    );
  });

  getScenarioValuesAfterCreatingScenarioWithPreExistingValuesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createScenarioWithPreviousYearSuccess, duplicateScenarioSuccess),
      map(({ scenario }) => getScenarioValues({ scenarioId: scenario.id }))
    );
  });

  getScenarioValuesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getScenarioValues),
      mergeMap(({ scenarioId }) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.getScenarioValues(companyId, scenarioId).pipe(
            map(values => getScenarioValuesSuccess({ scenarioId, values })), // TODO add all accounts to zero sum
            catchError((error) => of(getScenarioValuesFailure({ error })))
          )
        }
        return of(getScenarioValuesFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  renameScenarioEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setScenarioName),
      mergeMap(({ scenarioId, newName }) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.renameScenario(companyId, scenarioId, newName).pipe(
            map(scenarioId => setScenarioNameSuccess({ scenarioId, newName })),
            catchError((error) => of(setScenarioNameFailure({ error })))
          )
        }
        return of(setScenarioNameFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  deleteScenarioEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteScenario),
      mergeMap(({ scenarioId }) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.deleteScenario(companyId, scenarioId).pipe(
            map(() => deleteScenarioSuccess({ scenarioId })),
            catchError((error) => of(deleteScenarioFailure({ error })))
          )
        }
        return of(deleteScenarioFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  getFirstScenarioAvailableWhenScenarioIsDeletedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteScenarioSuccess),
      concatLatestFrom(() => [this.store.select(selectCurrentScenarioId)]),
      filter(([, scenarioId]) => !!scenarioId),
      map(([, scenarioId]) => getScenarioValues({ scenarioId: scenarioId! }))
    );
  });

  duplicateScenarioEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(duplicateScenario),
      mergeMap(({ scenarioId, scenarioName }) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.duplicateScenario(companyId, scenarioId, scenarioName).pipe(
            map((scenario) => duplicateScenarioSuccess({ scenario })),
            catchError((error) => of(duplicateScenarioFailure({ error })))
          )
        }
        return of(duplicateScenarioFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  createScenarioEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createScenario),
      filter(({ initDataWithPreviousYear }) => !initDataWithPreviousYear),
      mergeMap(({ scenarioName }) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId) {
          return this.budgetService.createScenario(companyId, scenarioName).pipe(
            map((scenario) => createScenarioSuccess({ scenario })),
            catchError((error) => of(createScenarioFailure({ error })))
          );
        }
        return of(createScenarioFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  createScenarioWithPreviousYearEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createScenario),
      filter(({ initDataWithPreviousYear }) => initDataWithPreviousYear),
      concatLatestFrom(() => [
        this.store.select(selectMonthlyAccountsWithinPrevSpan)
      ]),
      mergeMap(([{ scenarioName }, monthlyAccountsForSpan]) => {
        const companyId = this.localStorageService.getCompanyId();
        if (companyId && monthlyAccountsForSpan) {
          return this.budgetService.createScenarioWithPreviousYear(companyId, scenarioName, monthlyAccountsForSpan).pipe(
            map((scenario) => createScenarioWithPreviousYearSuccess({ scenario })),
            catchError((error) => of(createScenarioFailure({ error })))
          );
        }
        return of(createScenarioFailure({ error: new Error('Missing company id') }));
      })
    );
  });

  downloadPreviousYearDataAsCsvEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadPreviousYearCsv),
      concatLatestFrom(() => [
        this.store.select(selectMonthlyAccountsWithinPrevSpan),
        this.store.select(selectAccounts)
      ]),
      map(([, monthlyAccountsForPrevSpan, accounts]) => {
        const csv = this.budgetService.createBudgetCsvFromPreviousYear(monthlyAccountsForPrevSpan, accounts);
        const blob = new Blob([csv], {type: 'text/csv'});
        saveAs(blob, 'budget.csv');
      })
    )
  }, { dispatch: false });
}
