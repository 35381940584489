import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MonthlyAccount, MonthlyAccounts } from '../model/accounting.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Scenario } from '../store/scenario.model';
import { incrementYear } from '../model/period.model';
import { BexioAccount } from '../model/bexio.model';
import { LocalStorageService } from '../../home/services/local-storage.service';

export const CSV_SEPARATOR = ';';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {

  constructor(
    private functions: AngularFireFunctions,
    private localStorageService: LocalStorageService
  ) {
  }

  getScenarios = (companyId: string): Observable<Scenario[]> => {
    const callable = this.functions.httpsCallable('listScenarios');
    return callable({
      companyId,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  saveScenarioValue = (companyId: string, monthlyAccount: MonthlyAccount | MonthlyAccount[], scenarioId: string | undefined): Observable<string> => {
    const callable = this.functions.httpsCallable('updateScenario');
    return callable({
      companyId,
      monthlyAccount,
      scenarioId,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  getScenarioValues = (companyId: string, scenarioId: string): Observable<any> => {
    const callable = this.functions.httpsCallable('getScenario');
    return callable({
      companyId,
      scenarioId,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  createScenario = (companyId: string, scenarioName: string): Observable<Scenario> => {
    const callable = this.functions.httpsCallable('createScenario');
    return callable({
      companyId,
      scenarioName,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  createScenarioWithPreviousYear = (companyId: string, scenarioName: string, monthlyAccountsForSpan: MonthlyAccounts[]): Observable<Scenario> => {
    const budget = this.createBudgetFromLastYearMonthlyAccounts(monthlyAccountsForSpan);

    const callable = this.functions.httpsCallable('createScenario');
    return callable({
      companyId,
      scenarioName,
      budget,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  createBudgetFromLastYearMonthlyAccounts = (monthlyAccountsList: MonthlyAccounts[]): any =>
    monthlyAccountsList.reduce((budgetData, monthlyAccounts) => {
      const yearMonthBudgetData = monthlyAccounts.accountsValues.reduce((yearMonthBudgetData, accountValue) => {
        if (Number(accountValue.account_no) >= 3000 && accountValue.value !== 0) {
          return {
            ...yearMonthBudgetData,
            [accountValue.account_no]: accountValue.value
          }
        } else {
          return yearMonthBudgetData;
        }
      }, {});
      const newYearMonth = incrementYear(monthlyAccounts.yearMonth);
      return {
        ...budgetData,
        [newYearMonth]: yearMonthBudgetData
      };
    }, {});

  createBudgetCsvObjectFromPreviousYear = (monthlyAccountsForSpan: MonthlyAccounts[]): any =>
    monthlyAccountsForSpan.sort((a, b) => a.yearMonth.localeCompare(b.yearMonth))
      .reduce((accountsAcc: any, monthlyAccounts) => {
      return monthlyAccounts.accountsValues.reduce((subAccountsAcc: any, accountValue) => {
        if (Number(accountValue.account_no) < 3000) {
          return subAccountsAcc;
        }
        if (!subAccountsAcc[accountValue.account_no]) {
          return {
            ...subAccountsAcc,
            [accountValue.account_no]: [accountValue.value]
          }
        } else {
          subAccountsAcc[accountValue.account_no].push(accountValue.value);
          return subAccountsAcc;
        }
      }, accountsAcc);
    }, {});

  createBudgetCsvRowsFromPreviousYear = (monthlyAccountsForSpan: MonthlyAccounts[], accountsRef: BexioAccount[]): string[] => {
    const csvObject = this.createBudgetCsvObjectFromPreviousYear(monthlyAccountsForSpan);
    const accounts = Object.keys(csvObject);
    return accounts.filter(account => {
      const values: number[] = csvObject[account];
      return values.some(value => value != 0);
    }).map(account => {
      // todo add account label
      const label = accountsRef.find(accountRef => accountRef.account_no === account)?.name ?? '';
      const values: number[] = csvObject[account];
      return `${account} - ${label}; ${values.map(value => value !== 0 ? value.toString() : '').join(`${CSV_SEPARATOR} `)}`;
    });
  };

  createBudgetCsvFromPreviousYear = (monthlyAccountsForSpan: MonthlyAccounts[], accountsRef: BexioAccount[]): string => {
    const yearMonthHeader = monthlyAccountsForSpan.sort((a, b) => a.yearMonth.localeCompare(b.yearMonth))
      .map(monthlyAccounts => incrementYear(monthlyAccounts.yearMonth))
      .join(`${CSV_SEPARATOR} `);
    const header = `account${CSV_SEPARATOR} ${yearMonthHeader}`;
    const rows = this.createBudgetCsvRowsFromPreviousYear(monthlyAccountsForSpan, accountsRef);
    const fullCsvRows = [header, ...rows];
    return fullCsvRows.join('\n');
  };

  renameScenario = (companyId: string, scenarioId: string, scenarioName: string): Observable<string> => {
    const callable = this.functions.httpsCallable('renameScenario');
    return callable({
      companyId,
      scenarioId,
      scenarioName,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  deleteScenario = (companyId: string, scenarioId: string): Observable<void> => {
    const callable = this.functions.httpsCallable('deleteScenario');
    return callable({
      companyId,
      scenarioId,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

  duplicateScenario = (companyId: string, scenarioId: string, scenarioName: string): Observable<Scenario> => {
    const callable = this.functions.httpsCallable('duplicateScenario');
    return callable({
      companyId,
      scenarioId,
      scenarioName,
      accessToken: this.localStorageService.getAccessToken()
    });
  };

}
